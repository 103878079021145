<template>
	<v-container fluid>
		<div class="d-flex flex-wrap py-2 align-center">
			<v-btn class="ma-1" color="primary" small to="/autofacturacion">
				<v-icon left>mdi-arrow-left</v-icon>Volver a facturas
			</v-btn>
			<v-btn class="ma-1" color="primary" small @click="getAllData">
				<v-icon left>mdi-reload</v-icon>Recargar
			</v-btn>

			<v-switch
				class="mt-0 ml-1 align-self-center"
				label="Mostrar no facturables"
				hide-details
				v-model="mostrarNoFacturables"
			></v-switch>

			<v-alert
				dense
				text
				type="warning"
				class="ma-0 ml-2"
				v-if="this.contratos.filter(c => !c.facturable).length > 0"
			>{{ this.contratos.filter(c => !c.facturable).length }} canales no facturables</v-alert>

			<v-spacer></v-spacer>

			<v-btn
				:disabled="selected.length == 0 || selected.some(c => c.IBAN == null)"
				class="ma-1"
				color="secondary"
				small
				:loading="generandoAutofactura"
				@click="generarAutofacturaLote"
			>
				<v-icon left>mdi-cash-register</v-icon>Generar autofacturas
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="contratosFiltrados"
				:headers="contratosHeader"
				:calculate-widths="true"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				checkbox-color="secondary"
				:single-select="false"
				show-select
				class="fixed-checkbox"
				item-key="canal"
				v-model="selected"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="contratosFiltrados" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.NDevoluciones="{ item }">
					<span class="error--text">{{ item.NDevoluciones || '0' }}</span>
				</template>
				<template v-slot:item.importe="{ item }">
					<span class="font-weight-bold">{{ (item.importe) | eur }}</span>
				</template>
				<template v-slot:item.importeDevoluciones="{ item }">
					<span class="error--text font-weight-bold">{{ (item.importeDevoluciones) | eur }}</span>
				</template>
				<!-- <template v-slot:item.NCom="{ item }">
					<span class="error--text">{{ item.NCom || '0' }}</span>
				</template>
				<template v-slot:item.ImporteCom="{ item }">
					<span class="error--text">{{ item.ImporteCom || '0' }}</span>
				</template>-->

				<template v-slot:item.baseImponible="{ item }">
					<v-chip label>{{ (item.baseImponible * 1 || 0) | eur }}</v-chip>
				</template>

				<template v-slot:item.acciones="{ item }">
					<div class="d-flex align-center justify-end">
						<v-btn v-if="item.IBAN" @click="colaborador = item.canal" outlined color="secondary" icon>
							<v-icon>mdi-cash-register</v-icon>
						</v-btn>
						<v-alert
							class="mb-0 ml-2"
							dense
							type="info"
							text
							v-if="!item.facturable"
						>Nos faltan datos del colaborador</v-alert>
					</div>
				</template>
			</v-data-table>
		</v-card>

		<v-bottom-sheet
			scrollable
			:value="colaborador"
			@input="(v) => v === false ? (() => { colaborador = false; lineas = [] })() : null"
		>
			<v-card>
				<v-toolbar dense color="primary">
					<v-toolbar-title class="white--text">GENERAR FACTURA {{ colaborador }}</v-toolbar-title>
					<v-spacer />
					<v-btn icon @click="colaborador = false">
						<v-icon color="white">mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text class="pa-3" style="max-height: 1200px;">
					<v-container fluid>
						<v-data-table
							fixed-header
							:items="lineas"
							:headers="lineasHeader"
							:calculate-widths="true"
							:loading="loading"
							:footer-props="{
								showFirstLastPage: true,
								showCurrentPage: true,
								itemsPerPageOptions: [10, 25, 50],
							}"
							show-select
							:single-select="false"
							checkbox-color="success"
							v-model="selected"
						>
							<template v-slot:body.prepend="{ headers }">
								<table-filters :headers="headers" :items="lineas" v-model="inlineFilters2"></table-filters>
							</template>

							<template v-slot:item.Situacion="{ item }">
								<div class="d-flex align-center">
									<status-chip :value="item.Situacion" />
									<v-alert
										class="mb-0 ml-2"
										dense
										type="info"
										text
										v-if="!Number(item.aDevolver) && ['Baja', 'Baja por modificación'].includes(item.Situacion)"
									>Aún no ha sido facturado</v-alert>
								</div>
							</template>

							<template v-slot:item.CodigoContrato="{ item }">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-on="on"
											v-bind="attrs"
											link
											:to="`/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`"
											:color="Number(item.aDevolver) ? 'error' : 'secondary'"
											text
										>{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn>
									</template>
									<span>Detalles del contrato</span>
								</v-tooltip>
							</template>

							<template v-slot:item.Identificador="{ item }">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-on="on"
											v-bind="attrs"
											link
											:to="`/clientes/detalles?idCliente=${item.IdCliente}`"
											:color="Number(item.aDevolver) ? 'error' : 'secondary'"
											text
										>{{ item.Identificador }}</v-btn>
									</template>
									<span>Detalles del cliente</span>
								</v-tooltip>
							</template>

							<template v-slot:item.CodigoCUPS="{ item }">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-on="on"
											v-bind="attrs"
											link
											:to="`/detalles_cups?cups=${item.CodigoCUPS}`"
											:color="Number(item.aDevolver) ? 'error' : 'secondary'"
											text
										>{{ item.CodigoCUPS }}</v-btn>
									</template>
									<span>Detalles del CUPS</span>
								</v-tooltip>
							</template>

							<template v-slot:item.FechaContrato="{ item }">
								<span v-text="parseDate(item.FechaContrato)" />
							</template>
							<template v-slot:item.FechaAlta="{ item }">
								<span v-text="parseDate(item.FechaAlta)" />
							</template>
							<template v-slot:item.FechaBaja="{ item }">
								<span v-text="parseDate(item.FechaBaja)" />
							</template>

							<template v-slot:item.comision="{ item }">
								<v-chip
									v-if="cambiarImporte.contratos[item.CodigoContrato] == null"
									label
								>{{ (item.comision) | eur }}</v-chip>
								<v-chip v-else label>
									<s class="mr-2">{{ item.comision * 1 }}</s>
									{{ cambiarImporte.contratos[item.CodigoContrato].nuevo }}
									€
								</v-chip>
								<v-btn @click.stop="cambiarImporteF(item)" icon color="primary" small>
									<v-icon small>mdi-pencil</v-icon>
								</v-btn>
							</template>

							<template v-slot:item.aDevolver="{ item }">
								<span
									:class="[!Number(item.aDevolver) || 'error--text']"
								>{{ Number(item.aDevolver) ? 'Sí' : 'No' }}</span>
							</template>
						</v-data-table>
						<v-card-actions class="align-center">
							<v-alert
								class="mb-0"
								text
								v-if="repetido"
								dense
								type="error"
							>Ya existe una factura con este nombre</v-alert>
							<v-spacer />
							<v-alert
								class="mb-0"
								text
								v-if="Object.keys(cambiarImporte.contratos).length > 0"
								dense
								type="info"
							>{{ Object.keys(cambiarImporte.contratos).length }} importes cambiados</v-alert>
							<v-btn @click.stop="generarAutofactura" large text color="primary">Generar</v-btn>
						</v-card-actions>
					</v-container>
				</v-card-text>
			</v-card>
		</v-bottom-sheet>

		<LoadingSteps v-if="checksPanel" :value="checks" />

		<v-dialog
			v-if="cambiarImporte.contrato != null"
			:value="cambiarImporte.contrato != null"
			@input="(v) => v === false ? cambiarImporte.resolve() : null"
			@keydown.enter="cambiarImporte.resolve()"
			max-width="350"
		>
			<v-card class="pa-3">
				<v-form ref="cambioImporte">
					<!-- <v-col cols="auto">
						<v-btn @click.stop="cambiarImporte.reject" icon color="error">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-col>-->
					<v-text-field
						dense
						hide-details="auto"
						filled
						v-model="cambiarImporte.contratos[cambiarImporte.contrato].nuevo"
						:rules="[rules.req, rules.isNumber]"
					></v-text-field>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn small text color="error" @click.stop="cambiarImporte.reject">Cancelar</v-btn>
						<v-btn
							small
							text
							color="primary"
							@click.stop="$refs.cambioImporte.validate() ? cambiarImporte.resolve() : null"
						>Aceptar</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { perColumnFilter, parseDate, timeAgo } from '@/utils/index.js';
import { req, isNumber } from '@/utils/validations.js';

export default {
	components: {
		TableFilters: () => import('@/components/TableFilters.vue'),
		StatusChip: () => import('@/components/StatusChip.vue'),
		LoadingSteps: () => import('@/components/LoadingSteps.vue'),
	},
	data() {
		return {
			p: {
				show: false,
				resolve: null,
				reject: null,
			},
			contratos: [],
			lineas: [],
			loading: false,
			repetido: false,
			selected: [],
			ignoreSelect: [],
			checksPanel: false,
			checks: {},
			rules: { req, isNumber },
			lineasHeader: [
				{ text: 'Situacion', value: 'Situacion', dataType: 'select' },
				{ text: 'Canal', value: 'canal', },
				{ text: 'SubAgente', value: 'subAgente', },
				{ text: 'Contrato', value: 'CodigoContrato' },
				// { text: 'Fecha Contrato', value: 'FechaContrato', dataType: 'date' },
				{ text: 'Fecha Alta', value: 'FechaAlta', dataType: 'date' },
				{ text: 'Fecha Baja', value: 'FechaBaja', dataType: 'date' },
				{ text: 'Meses Activado', value: 'Diferencia' },
				{ text: 'CUPS', value: 'CodigoCUPS' },
				{ text: 'Cliente', value: 'Identificador' },
				{ text: 'Nombre Cliente', value: 'Denominacion' },
				{ text: 'Base Imponible', value: 'comision' },
				{ text: 'Porcentaje especial', value: 'porcentajeEspecial' },
				{ text: 'Devolución', value: 'aDevolver', dataType: 'bool' },
				// { text: 'A DEVOLVER', value: 'aDevolver' },

			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters2[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
			contratosHeader: [
				{ text: 'Colaborador', value: 'canal' },
				{ text: 'Nº Contratos', value: 'NContratos' },
				{ text: 'Importe', value: 'importe' },
				{ text: 'Nº Dev', value: 'NDevoluciones' },
				{ text: 'Importe Dev', value: 'importeDevoluciones' },
				// { text: 'Nº COM', value: 'NCom' },
				// { text: 'Importe Com', value: 'ImporteCom' },
				{ text: 'Base imponible', value: 'baseImponible' },
				{ text: 'Acciones', value: 'acciones', filterable: false, sortable: false, align: 'center' },

			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
			inlineFilters: {},
			inlineFilters2: {},
			colaborador: null,
			autofactura: {
				fecha: [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()].join('-'),
				nDoc: null,
				showDate: false
			},
			generandoAutofactura: false,

			mostrarNoFacturables: false,
			colaboradores: [],

			cambiarImporte: {
				contrato: null,
				contratos: {}
			}
		}
	},
	computed: {
		fechaFormateada() {
			return parseDate(this.autofactura.fecha)
		},
		contratosFiltrados() {
			return this.mostrarNoFacturables ? this.contratos : this.contratos.filter(c => c.facturable)
		},
		percentDiff() {
			return parseFloat((
				(Number(this.cambiarImporte.contratos[this.cambiarImporte.contrato].nuevo) - Number(this.cambiarImporte.contratos[this.cambiarImporte.contrato].viejo))
				/ Number(this.cambiarImporte.contratos[this.cambiarImporte.contrato].nuevo))
				* 100).toFixed(2)
		}
	},
	async mounted() {
		this.getAllData();
	},
	methods: {
		parseDate,
		timeAgo,
		async getAllData() {
			this.loading = true;
			this.$set(this.checks, 'colaboradores', {
				progress: 'loading',
				texto: 'Cargando colaboradores'
			})
			this.colaboradores = [];
			this.contratos = [];
			await this.getColaboradores();
			this.checks.colaboradores.progress = true;

			const perChunk = 5;

			const result = this.colaboradores.reduce((resultArray, item, index) => {
				const chunkIndex = Math.floor(index / perChunk)

				if (!resultArray[chunkIndex]) {
					resultArray[chunkIndex] = [] // start a new chunk
				}

				resultArray[chunkIndex].push(item)

				return resultArray
			}, [])

			// result.forEach( async chunk => await Promise.all( chunk.map( col => this.getContratos(col.NombreContrato) ) )  )

			for (const chunk of result) {

				await Promise.all(chunk.map(col => this.getContratos(col.esSubAgente ? col.name : col.NombreContrato)));

				await this.$nextTick();

				for (const col of chunk) {
					this.$delete(this.checks, col.NombreContrato);
					this.$delete(this.checks, col.name);
				}

				this.$set(this.checks, 'yaCargados', { progress: true, texto: `${this.contratos.length} colaboradores cargados` });

			}

			// await Promise.all(this.colaboradores.map(col => this.getContratos(col.NombreContrato)));
			this.loading = false;
		},

		async getContratos(canal) {
			return new Promise((resolve, reject) => {
				this.$set(this.checks, canal, {
					progress: 'loading',
					texto: `Cargando contratos de ${canal}`
				})


				axios({
					method: 'GET',
					url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
					params: {
						canal,
						token: this.$store.getters.getJwtEmpresa,
					}
				}).then(({ data }) => {
					if (data.lineas.length == 0) {
						this.checks[canal].progress = true;
						resolve();
						return;
					}
					const importe = data.lineas.filter(c => c.aDevolver == "0").reduce((a, c) => Number(c.comision) + a, 0)
					const importeDevoluciones = data.lineas.filter(c => c.aDevolver == "1").reduce((a, c) => Number(c.comision) + a, 0);
					this.contratos.push({
						...this.colaboradores.find(col => col.NombreContrato == canal || col.name == canal),
						canal,
						NContratos: data.lineas.filter(c => c.aDevolver == "0").length,
						importe,
						NDevoluciones: data.lineas.filter(c => c.aDevolver == "1").length,
						importeDevoluciones,
						baseImponible: importe + importeDevoluciones,
					})
					this.checks[canal].progress = true;
					resolve();
				}).catch((err) => {
					console.error(err, canal);
					this.checks[canal].progress = 'error';
					resolve();
				})
			})
		},
		async getColaboradores() {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/colaboradores.php`,
					params: {
						token: this.$store.getters.getJwtEmpresa,
					},
				}).then((res) => {
					this.colaboradores = res.data.map((c) => {
						c.name = c.Nombre;
						c.Nombre = [...new Set([c.NombreContrato, c.Nombre])].join("__");
						c.esCanal = c.ancestor_id == null;
						c.esSubAgente = c.ancestor_id != null;
						c.facturable = [c.IBAN, c.porcentajeIva, c.porcentajeIrpf, c.direccion, c.cp, c.ciudad, c.provincia, c.cif].every(con => con != null);
						return c;
					}).filter(c => {
						// console.log({ fac: c.facturable, sub: c.esSubAgente, nombre: c.NombreContrato })
						return c.facturable
					}).sort((a, b) => (a.name) < b.name ? -1 : 1);
					resolve();
				});
			})
		},
		async generarAutofactura() {
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario, IdAgente } = str.iss;

			axios({
				method: 'POST',
				url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
				data: {
					canal: [this.colaborador],
					fecha: new Date(this.autofactura.fecha).getTime(),
					token: this.$store.getters.getJwtEmpresa,
					cambiosImporte: this.cambiarImporte.contratos,
					selected : this.selected.map(item => item.CodigoContrato),
					IdAgente
				}
			}).then(res => {
				this.colaborador = null;
				this.$root.$emit('snack', 'Autofactura generada correctamente')
				this.getAllData();
			})
		},
		async generarAutofacturaLote() {
			try {
				this.generandoAutofactura = true;
				await axios({
					method: 'POST',
					url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
					data: {
						canal: this.selected.map(f => f.canal),
						fecha: new Date(this.autofactura.fecha).getTime(),
						token: this.$store.getters.getJwtEmpresa,
					}
				})
				this.generandoAutofactura = false;
				this.$root.$emit('snack', 'Autofacturas generadas correctamente')
				this.$router.push({ name: 'Autofacturas' })
			} catch (e) {
				this.generandoAutofactura = false;
				this.p.show = false;
				this.$root.$emit('snack', 'Ha ocurrido un error al generar las autofacturas')
			}

			return

		},
		async cambiarImporteF({ CodigoContrato, comision }) {
			const auxPromise = () => {
				return new Promise(async (resolve, reject) => {
					this.$set(this.cambiarImporte.contratos, CodigoContrato, { nuevo: comision * 1, viejo: comision * 1 })
					this.cambiarImporte.contrato = Number(CodigoContrato);
					this.cambiarImporte.resolve = resolve;
					this.cambiarImporte.reject = reject;
				})
			}

			try {
				await auxPromise();
				if (this.cambiarImporte.contratos[CodigoContrato].nuevo == this.cambiarImporte.contratos[CodigoContrato].viejo) throw new Error('Importe igual al anterior');
				this.cambiarImporte.contrato = null;
				this.cambiarImporte.resolve = null;
				this.cambiarImporte.reject = null;
				console.log(this.cambiarImporte.contratos)
			} catch (e) {
				this.cambiarImporte.contrato = null;
				this.cambiarImporte.resolve = null;
				this.cambiarImporte.reject = null;
				this.$delete(this.cambiarImporte.contratos, CodigoContrato);
			}
		}
	},
	watch: {
		async colaborador(n) {
			if (n) {
				// console.log(n)
				this.loading = true;
				const { data } = await axios({
					method: 'GET',
					url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
					params: {
						canal: this.colaborador,
						token: this.$store.getters.getJwtEmpresa
					}
				})

				this.lineas = data.lineas.map(l => {
					l.Denominacion =
						l.RazonSocialTitular ||
						[l.NombreTitular, l.Apellido1Titular, l.Apellido2Titular]
							.filter((i) => !!i)
							.join(" ")
					l.aDevolver = Boolean(Number(l.aDevolver))
					return l
				});

				if (data.ultimoNombre) {

					let [num, year] = data.ultimoNombre.split('/')

					if (new Date().getFullYear() != year) num = 0;

					this.autofactura.nDoc = [('0000' + (Number(num) + 1)).slice(-4), new Date().getFullYear()].join('/');
				} else {
					this.autofactura.nDoc = `0001/${new Date().getFullYear()}`;
				}
			} else {
				this.lineas = [];
			}
			this.loading = false;
		},
		loading(val) {
			if (val) this.checksPanel = true;
			else {
				setTimeout(() => {
					this.checksPanel = false;
					this.checks = {};
				}, 1000);
			}
		}
	}
}
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}

.triangle {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;

	border-top: 10px solid white;
	margin: 0 auto;
}
</style>